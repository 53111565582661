const apiBaseUrlList = [
  'http://127.0.0.1:8005',
  'https://www.zwglass.cn',
  'https://api-records.zwglass.cn',
];

const layoutForm = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const formItemLayout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 22,
      offset: 2,
    },
    sm: {
      span: 18,
      offset: 6,
    },
  },
};

const customConfig = {
  apiBaseUrl: apiBaseUrlList[2],
  adminTokenKey: 'adminToken',
  expressInfoKey: 'expressinfo',
  companySingleLogoUrl: 'https://purchase-records-1254307677.cos.ap-chengdu.myqcloud.com/base-images/%E5%B1%95%E6%9C%9B%E7%9C%BC%E9%95%9CSingleLogo_2019.png',
  companyLogoUrl: 'https://purchase-records-1254307677.cos.ap-chengdu.myqcloud.com/base-images/%E5%B1%95%E6%9C%9B%E7%9C%BC%E9%95%9CLogoText2019.png',
  purchaseStates: ['订单交易完成', '定制中', '已发货'],
  salePlatforms: ['未知', '门店', '淘宝', '小程序', '微信-方灵芝', '微信-展望眼镜'],
  payMethods: ['未知', '微信支付', '支付宝支付', '刷卡', '现金'],
  formCommonStyle: { layoutForm: layoutForm, formItemLayout: formItemLayout, tailFormItemLayout: tailFormItemLayout },
  selectComponentWidth: 260,
  purchaseRecordStates: {0: '订单交易完成', 1: '未付款', 2: '定制中', 3: '待收货', 4: '售后处理中', 5: '订单已取消(未付款等原因)'},
  purchaseRecordStatesColor: {0: '#F0F0F0', 1:'#FFFFCC', 2:'#90EE90', 3:'#FFFFFF', 4:'#FF3300', 5: '#FFCC00'},
  pagesName: {
    purchaseRecords: 'purchaseRecordsPage',
  }
}

export default customConfig;

/**
 * { formCommonStyle } 使用方法
 * import customConfig from '../../custom_config';
    const formCommonStyle = customConfig.formCommonStyle;
    const styleLayoutForm = formCommonStyle.layoutForm;
    const styleFormItemLayout = formCommonStyle.formItemLayout;
    const styleTailFormItemLayout = formCommonStyle.tailFormItemLayout;

    <Form {...styleLayoutForm} ......>
        <Form.Item {...styleFormItemLayout} ......><Imput /></Form.Item>
        <Form.Item {...styleTailFormItemLayout}>
            <Space><Button>Cancel</Button><Button>Submit</Button></Space>
        </Form.Item>
    </Form>
 *
 */
